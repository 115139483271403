import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'


const routes = [
    {
        path: '/',
        component: () => import('./components/index.vue')
    }
    
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
const vm = createApp(App)
vm.use(router)
vm.use(ElementPlus)
vm.mount('#app')

